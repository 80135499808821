'use client'; // Required for client-side hooks

import { useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default function Error({ error, reset }) {
    const [isErrorLogged, setIsErrorLogged] = useState(false);
    const [logMessage, setLogMessage] = useState('');

    // Handle log and download when retry button is clicked
    const handleRetry = async () => {
        if (!isErrorLogged) {
            // Get current date and time
            const now = new Date();
            const zipFileName = `resume-builder-error-report-${now.toISOString()}.zip`;

            // Log the full error stack trace
            const errorLog = `
            Error: ${error.toString()}
            Stack Trace: ${error.stack || 'No stack trace available'}
            `;

            // Collect sessionStorage data
            const storedData = JSON.stringify(sessionStorage, null, 2);

            // Create ZIP file
            const zip = new JSZip();
            zip.file("error-log.txt", errorLog);
            zip.file("sessionStorage.json", storedData);

            // Generate the zip and trigger download
            const zipBlob = await zip.generateAsync({ type: "blob" });
            saveAs(zipBlob, zipFileName);

            // Clear sessionStorage after download
            sessionStorage.clear();

            // Mark as logged to prevent re-downloading
            setIsErrorLogged(true);
            setLogMessage('The error report has been downloaded. You can retry now.');
        }

        // Reset the app and try again
        reset();
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Oops! Something went wrong.</h1>
            <p style={styles.message}>An unexpected error occurred. We have logged the error, and To make the app better please mail us the downloaded report at <b>support@dev4u.in</b></p>
            {logMessage && <p style={styles.logMessage}>{logMessage}</p>}
            <button style={styles.retryButton} onClick={handleRetry}>Download Report & Retry</button>
        </div>
    );
}

// Basic UI Styles
const styles = {
    container: {
        textAlign: 'center',
        padding: '2rem',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f8f9fa',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        margin: '2rem auto',
        maxWidth: '500px',
    },
    title: {
        fontSize: '2rem',
        color: '#333',
    },
    message: {
        fontSize: '1rem',
        color: '#555',
        marginBottom: '1.5rem',
    },
    logMessage: {
        fontSize: '1rem',
        color: '#28a745',
        marginBottom: '1rem',
    },
    retryButton: {
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        padding: '0.75rem 1.5rem',
        fontSize: '1rem',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};
